var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-eye ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary","elevation":"0","dense":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Preview "+_vm._s(_vm.domain))]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.handleOpenExternal(null)}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1),_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('common.close', _vm.locale))+" ")])],1)],1),(_vm.dialog)?_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"dtouchMobilePreview",staticStyle:{"padding":"5px"},attrs:{"xs2":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs8":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('dtouch')}}},[_vm._v(" refresh ")])],1),_c('v-flex',{staticStyle:{"padding-left":"5px"},attrs:{"xs4":""}},[_c('v-btn',{attrs:{"dark":"","small":"","block":"","color":"primary"},on:{"click":function($event){return _vm.handleOpenExternal('mobile')}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('preview',{attrs:{"url":_vm.prepareDtouch(_vm.LMUDtouch),"locale":_vm.locale,"size":{
                w: 375,
                h: 799,
              },"className":"dtouchMobilePreview","name":"if-dtouch"}})],1)],1)],1),_c('v-flex',{staticClass:"dtouchTotemPreview",staticStyle:{"padding":"5px"},attrs:{"xs3":"","id":"totem"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs9":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('totem')}}},[_vm._v(" refresh ")])],1),_c('v-flex',{staticStyle:{"padding-left":"5px"},attrs:{"xs3":""}},[_c('v-btn',{attrs:{"dark":"","small":"","block":"","color":"primary"},on:{"click":function($event){return _vm.handleOpenExternal('totem')}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('preview',{attrs:{"url":_vm.prepareTotem(_vm.LMUTotem),"locale":_vm.locale,"size":{
                w: 1080,
                h: 1920,
              },"className":"dtouchTotemPreview","name":"if-totem"}})],1)],1)],1),_c('v-flex',{staticClass:"dtouchDesktopPreview",staticStyle:{"padding":"5px"},attrs:{"xs7":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs11":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('desktop')}}},[_vm._v(" refresh ")])],1),_c('v-flex',{staticStyle:{"padding-left":"5px"},attrs:{"xs1":""}},[_c('v-btn',{attrs:{"dark":"","small":"","block":"","color":"primary"},on:{"click":function($event){return _vm.handleOpenExternal('desktop')}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)],1),_c('v-flex',{attrs:{"xs12":""}},[_c('preview',{attrs:{"url":_vm.prepareDesktop(_vm.LMUDesktop),"locale":_vm.locale,"size":{
                w: 1920,
                h: 1080,
              },"className":"dtouchDesktopPreview","name":"if-desktop"}})],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }